import { jackColors } from "assets/colors";
import { JackIcons } from "assets/jackIcons/parent";
import { phoneCode, phoneSimpleFormatter } from "assets/phoneCode";
import { Banner } from "components/Banner";
import { ButtonJack } from "components/ButtonsJack/parent";
import { PhoneSelectionJack } from "components/inputs/selection";
import { TextFieldJack } from "components/inputs/textfield";
import { useModalHook } from "components/Modals";
import { PinInput } from "components/PINModal/pin";
import { CenteredModal } from "modals/centeredComponents";
import { pluralize } from "pageComponents/invoiceComponents/data/formatter";
import { KYBHeader } from "pageComponents/kybJackComponents/header";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Modal } from "reactstrap";
import { fetch, useMutation } from "tools/api";
import { decryptToken, encryptToken } from "tools/api/functions/hash";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../components/Text";
import phoneImg from "../../../public/images/PhoneOTP.png";
import { Layout } from "../components";
import { getSalus } from "../logics";
import { useLanguage } from "public/locales/translationFunctions";

const convertPhoneNumber = (phoneNumber) => {
  const last4Digits = phoneNumber?.slice(-4);
  const country3Digit = phoneNumber?.slice(0, 3);
  const country4Digit = phoneNumber?.slice(0, 4);

  const countryCode =
    phoneCode.find(
      ({ value }) => value == country3Digit || value == country4Digit
    )?.value || "+62";

  return `${countryCode} •••• - •••• - ${last4Digits}`;
};

const propertiesDecider = (type) => {
  if (type == "WHATSAPP")
    return { img: "/images/whatsapp.svg", title: "Whatsapp" };

  return { img: "/images/chat.svg", title: "SMS" };
};

export const OTPModal = ({
  isOpen,
  mobile,
  isError,
  onAction,
  loading,
  type,
}) => {
  const isID = localStorage.getItem("locale") == "id";
  if (!isOpen) return null;
  const { t } = useTranslation("login/login");
  const [pin, setPin] = useState("");

  const [timer, setTimer] = useState(0);

  const ResendText = (props) => <GothamMedium className="font12" {...props} />;

  useEffect(() => {
    if (!timer) return;
    let interval = setInterval(async () => {
      setTimer((prevTime) => {
        if (prevTime < 1) return prevTime;
        const result = prevTime - 1;
        return result;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const handleSubmit = (pin) => {
    onAction("submit", pin);
    setPin("");
  };

  const { img, title } = propertiesDecider(type);
  const remainingInvalidAttempt = decryptToken(
    localStorage.getItem("OTL") || ""
  );
  const validRangeAttempt =
    remainingInvalidAttempt < 3 && remainingInvalidAttempt > 0;
  const showBanner = !!remainingInvalidAttempt && validRangeAttempt;

  return (
    <CenteredModal
      toggle={() => onAction("toggle")}
      isOpen={isOpen}
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        maxWidth: 420,
      }}
    >
      <div className="w-100 d-flex justify-content-between">
        <JackIcons
          name="arrow-back"
          fill={jackColors.black34}
          style={{ height: 24, width: 24, cursor: "pointer" }}
          onClick={() => onAction("back")}
        />
        <JackIcons
          name="Close"
          fill={jackColors.black34}
          style={{ height: 24, width: 24, cursor: "pointer" }}
          onClick={() => onAction("toggle")}
        />
      </div>
      <img src={img} style={{ marginTop: 16 }} />
      <GothamMedium
        className="font20"
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        {t("Input Verification Code")}
      </GothamMedium>
      <GothamRegular
        style={{
          color: jackColors.grey90,
          marginBottom: 32,
          textAlign: "center",
          maxWidth: 320,
        }}
      >
        {/* Transfez (part of Jack) have sent the code via {title} to{" "} */}
        <Trans i18nKey={t("Dynamic.sentCode", { title })} />
        {convertPhoneNumber(mobile)}.
      </GothamRegular>
      {!!isError && (
        <GothamRegular style={{ marginBottom: 32, color: jackColors.redDC }}>
          {isID
            ? "Kode tidak berfungsi, coba ketik ulang atau kirim ulang kode."
            : "The code didn’t work, try typing it again or resend code."}
        </GothamRegular>
      )}{" "}
      {showBanner && (
        <Banner
          title={`${t("Incorrect code:")} ${remainingInvalidAttempt} ${t(
            pluralize(remainingInvalidAttempt, "attempt")
          )} ${t("left")}`}
          titleStyle={{ marginTop: 6 }}
          type="warning"
          style={{ width: 230, marginBottom: 32 }}
        />
      )}
      {
        <PinInput
          isPassword={false}
          length={7}
          onChange={(pin) => setPin(pin)}
          value={pin}
          setValue={setPin}
          onSubmit={() => {
            if (pin.length < 7) return;
            handleSubmit(pin);
          }}
        />
      }
      <ButtonJack
        isLoading={loading}
        className="w-100"
        style={{ marginTop: 32, marginBottom: 32 }}
        disabled={pin.length < 7}
        onClick={() => handleSubmit(pin)}
      >
        {t("Continue")}
      </ButtonJack>
      {timer ? (
        <ResendText style={{ color: jackColors.grey90 }}>
          {t("Code has been sent")} ({timer}
          {t("s")})
        </ResendText>
      ) : (
        <ResendText
          style={{ cursor: "pointer", marginBottom: 32 }}
          onClick={() => {
            onAction("resend");
            setTimer(60);
          }}
        >
          {t("Resend code")}
        </ResendText>
      )}
      <ContactUS />
    </CenteredModal>
  );
};

const OTPOption = ({
  type = "SMS",
  onClick,
  phoneNumber,
  isLoading,
  disabled,
  disabledText = "",
}) => {
  const { img, title } = propertiesDecider(type);
  const { t } = useTranslation("login/login");
  if (isLoading) return <Skeleton style={{ width: "100%", height: 66 }} />;

  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "100%",
          border: "1px solid #fcfcfc",
          padding: 12,
          display: "flex",
          justifyContent: "space-between",
          cursor: disabled ? "not-allowed" : "pointer",
          borderRadius: 8,
          filter: disabled ? "grayscale(100%)" : "none",
          boxShadow: disabled ? "none" : "0px 4px 8px 0px #3232320F",
          backgroundColor: disabled ? jackColors.neutral400 : "transparent",
          zIndex: 10,
        }}
        onClick={() => {
          if (disabled) return;
          onClick(type);
        }}
      >
        <div className="d-flex" style={{ gap: 12 }}>
          <img src={img} style={{ opacity: disabled ? 0.4 : 1 }} />
          <div>
            <GothamMedium
              style={{
                color: disabled ? jackColors.neutral600 : jackColors.neutral900,
              }}
            >
              {title}
            </GothamMedium>
            <GothamRegular
              className="font12"
              style={{ color: disabled ? jackColors.neutral600 : "#909098" }}
            >
              {t("send to")} {convertPhoneNumber(phoneNumber)}
            </GothamRegular>
          </div>
        </div>
        <img
          src="/images/arrow-ios-forward.svg"
          style={{ opacity: disabled ? 0.4 : 1 }}
        />
      </div>
      {disabled && (
        <div
          style={{
            marginTop: "-16px",
            padding: `24px 12px 8px 12px`,
            borderRadius: 12,
            border: `1px solid ${jackColors.neutral400}`,
            backgroundColor: jackColors.neutral200,
            cursor: "not-allowed",
          }}
          className="d-flex align-items-center"
        >
          <JackIcons
            name="info"
            fill={jackColors.neutral700}
            style={{ height: 16, width: 16, cursor: "pointer", marginRight: 4 }}
          />
          <GothamRegular
            className="font12"
            style={{
              color: jackColors.neutral700,
            }}
          >
            {disabledText}
          </GothamRegular>
        </div>
      )}
    </div>
  );
};

const ContactUS = ({ onClick }) => {
  const { t } = useTranslation("login/login");
  return (
    <GothamRegular style={{ color: jackColors.grey90 }} className="font12">
      <Trans
        i18nKey={t("Dynamic.contactUs")}
        components={[<TextInlineMedium />]}
      />
    </GothamRegular>
  );

  return (
    <div className="w-100 text-center" onClick={onClick}>
      <GothamRegular className="font12" style={{ color: "#909098" }}>
        Got an issue?{" "}
        <TextInlineMedium
          style={{
            color: "#343434",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Contact Us
        </TextInlineMedium>
      </GothamRegular>
    </div>
  );
};

export const OTPOPtionModal = ({
  isOpen,
  toggle,
  onClick,
  phoneNumber,
  customerSupportLink = "",
  isLoading,
}) => {
  // const handleContactUs = () => window.open(customerSupportLink, "_blank");
  const { isEnglish } = useLanguage();
  const {
    data: dataStatusOTP,
    loading: loadingStatusOTP,
    refetch: refetchStatusOTP,
  } = fetch({
    url: "/otp/status",
    woInit: true,
    defaultValue: {},
  });

  const { is_sms_otp_active, sms_otp_active_time } = dataStatusOTP || {};
  const isDisabledOtpSMS = !is_sms_otp_active;
  const startHourDisabledSMS = sms_otp_active_time?.split("-")[0];
  const endHourDisabledSMS = sms_otp_active_time?.split("-")[1];
  const spanBoldSMS = (
    <span style={{ fontFamily: "GothamMedium" }}>
      {startHourDisabledSMS}:00 {isEnglish ? "to" : "-"} {endHourDisabledSMS}:00
      WIB
    </span>
  );

  const disabledTextSMS = isEnglish ? (
    <>SMS verification is available from {spanBoldSMS}</>
  ) : (
    <>Verifikasi SMS tersedia pukul {spanBoldSMS}</>
  );

  const handleContactUs = () => {};
  useEffect(() => {
    if (isOpen) {
      refetchStatusOTP();
    }
    //reset the localstorage
    localStorage.removeItem("OTL");
  }, [isOpen]);
  const { t } = useTranslation("login/login");
  return (
    <CenteredModal
      toggle={toggle}
      isOpen={isOpen}
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        maxWidth: 420,
        padding: 20,
        gap: 32,
      }}
    >
      <div onClick={toggle} className="w-100 d-flex justify-content-end">
        <JackIcons
          name="Close"
          fill={jackColors.black34}
          style={{ height: 24, width: 24, cursor: "pointer" }}
        />
      </div>
      <div className="w-100 d-flex-column text-center">
        <GothamMedium className="font20 mb-4">
          {t("We will send you a verification code")}
        </GothamMedium>
        <GothamRegular style={{ color: "#909098" }}>
          {t("Where should we send it?")}
        </GothamRegular>
      </div>
      <div
        className="w-100"
        style={{ display: "flex", flexDirection: "column", gap: 16 }}
      >
        <OTPOption
          type="WHATSAPP"
          phoneNumber={phoneNumber}
          onClick={onClick}
          isLoading={isLoading}
        />
        <OTPOption
          type="SMS"
          phoneNumber={phoneNumber}
          onClick={onClick}
          isLoading={isLoading}
          disabled={isDisabledOtpSMS}
          disabledText={disabledTextSMS}
        />
      </div>
      <ContactUS onClick={handleContactUs} />
    </CenteredModal>
  );
};

export const OTPSequenceModal = ({
  isOpen,
  toggle,
  isLoading,
  mobile,
  onClickSubmitOTP,
  handleRequestOTP,
  isErrorOTP,
}) => {
  const [otpWith, setOTP] = useState("");

  useEffect(() => {
    if (otpWith) return setOTP("");
  }, [isOpen]);
  const { t } = useTranslation("settings-jack");

  if (otpWith)
    return (
      <OTPModal
        isOpen={isOpen}
        onAction={async (type, otp_code) => {
          const isToggle = type == "toggle";
          const isResend = type == "resend";
          const isBack = type == "back";

          if (isBack) return setOTP("");

          if (isToggle) return toggle();

          if (isResend) return handleRequestOTP(otpWith);

          return onClickSubmitOTP(otp_code);
        }}
        type={otpWith}
        mobile={mobile}
        isError={isErrorOTP}
        loading={isLoading}
      />
    );

  return (
    <OTPOPtionModal
      phoneNumber={mobile}
      isOpen={isOpen}
      toggle={toggle}
      onClick={(type) => {
        handleRequestOTP(type);
        setOTP(type);
      }}
      isLoading={isLoading}
    />
  );
};

const SuspendedText = () => (
  <GothamRegular style={{ textAlign: "center", color: "#909098" }}>
    To keep your account safety, we will temporarily suspend it. Please contact{" "}
    <TextInlineMedium style={{ color: "#343434" }}>Jack Team</TextInlineMedium>{" "}
    for reactivation.
  </GothamRegular>
);

const BlockOrSuspendPropertiesDecider = ({ condition = "password" }) => {
  const suspendedIcon = "/images/suspended-account.svg";
  const blockedTitle = "Too many failed login attempts";

  const suspendedByPinTitle = "You’ve entered too many wrong PIN";
  const suspendedByOTPTitle = "You’ve entered too many wrong OTP";

  const isSuspendedLogin = condition == "password";
  const isSuspendedByPIN = condition == "pin";
  const isSuspendedByOTP = condition == "otp";

  return {
    icon: suspendedIcon,
    title: isSuspendedLogin
      ? blockedTitle
      : isSuspendedByPIN
      ? suspendedByPinTitle
      : isSuspendedByOTP
      ? suspendedByOTPTitle
      : "",
    // desc: <SuspendedText />,
    desc: isSuspendedLogin
      ? "Dynamic.failedLogin"
      : isSuspendedByPIN
      ? "Dynamic.invalidPin"
      : isSuspendedByOTP
      ? "Dynamic.invalidOtp"
      : "",
  };
};

export const SuspendedUserModal = ({ isOpen, toggle, condition }) => {
  const { icon, title, desc } = BlockOrSuspendPropertiesDecider({
    condition,
  });
  const { t } = useTranslation("login/login");

  return (
    <CenteredModal
      toggle={toggle}
      isOpen={isOpen}
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        maxWidth: 420,
        padding: 20,
        gap: 32,
      }}
    >
      <div
        style={{
          padding: "32px 0px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 16,
        }}
      >
        <img src={icon} style={{ width: 150, height: 150 }} />
        <GothamMedium style={{ textAlign: "center" }} className="font20">
          {t(title)}
        </GothamMedium>
        <GothamRegular style={{ textAlign: "center", color: "#909098" }}>
          <Trans
            i18nKey={t(desc)}
            components={[<TextInlineMedium style={{ color: "#343434" }} />]}
          />
        </GothamRegular>
        {/* <GothamRegular>{desc}</GothamRegular> */}
      </div>
      <div
        className="w-100"
        style={{ display: "flex", flexDirection: "column", gap: 16 }}
      >
        {/* {buttonText && (
          <ButtonJack onClick={onClickButton}>{buttonText}</ButtonJack>
        )} */}
        <ButtonJack type="outline" onClick={toggle}>
          {t("Close")}
        </ButtonJack>
      </div>
      {/* <ContactUS onClick={handleContactUs} /> */}
    </CenteredModal>
  );
};

export const setRemainingOTPAttempts = (error, handleMaxError) => {
  const remaining = error?.response?.data?.remaining_attempt;

  if (!remaining) {
    handleMaxError();
    localStorage.removeItem("OTL");
  }
  localStorage.setItem("OTL", encryptToken(`${remaining}`));
};

export const NewUserOTPModal = ({
  isOpen,
  toggle,
  onSubmit,
  loadingReqOTP = false,
  additionalPayload,
  defaultPhoneNumber,
}) => {
  const { t } = useTranslation("register/register");
  const useFormObj = useForm();
  const { handleSubmit, watch, setValue } = useFormObj;
  const { isOpen: isOpenOTP, close: closeOTP, open: openOTP } = useModalHook();

  const mobile = watch("mobile");
  const countryCode = watch("country_code");
  const { mutation: requestOTP, loading: loadingRequestOTP } = useMutation({
    url: "/authenticate/register_phone_otp",
    method: "post",
  });

  useEffect(() => {
    if (defaultPhoneNumber) {
      setValue("mobile", defaultPhoneNumber?.slice(3, -1));

      const options = phoneSimpleFormatter(phoneCode);
      const dv = phoneCode?.find(
        ({ value }) => value == defaultPhoneNumber?.slice(0, 3)
      );

      setValue(
        "country_code",
        options?.find(({ value }) => value == dv?.code)
      );
    }
  }, [defaultPhoneNumber]);

  const currentMobileNumber = useMemo(() => {
    const code = countryCode?.label?.split("-")?.[1];
    const phoneNumber = `${code}${mobile}`;
    return phoneNumber;
  }, [mobile, countryCode?.label]);
  return (
    <>
      <Modal
        className="my-0"
        contentClassName="modal-full"
        isOpen={isOpen}
        toggle={toggle}
        keyboard={false}
      >
        <KYBHeader onClickLogout={toggle} />
        <Layout woIcon>
          <img src={phoneImg} width={200} height={200} />
          <div style={{ textAlign: "center" }}>
            <GothamMedium className="font24">
              {t("Start by Verifying Your Number")}
            </GothamMedium>
            <GothamRegular style={{ color: "#909098", marginTop: 16 }}>
              {t(
                "We’ll send you a verification code. Please make sure it’s an active phone number."
              )}{" "}
            </GothamRegular>
          </div>
          <div
            className=" w-100"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "16px",
              }}
              className="w-100"
            >
              <PhoneSelectionJack
                name="country_code"
                useFormObj={useFormObj}
                label={t("Country Code")}
                containerStyle={{ height: 0 }}
              />
              <TextFieldJack
                name="mobile"
                label={t("Phone Number")}
                required
                placeholder="E.g., 8123456789"
                type="number"
                useFormObj={useFormObj}
                style={{ width: "100%" }}
                // error={(error["mobile"])}
              />
            </div>
            <ButtonJack
              rightIcon={<JackIcons name={"arrow_forward"} fill="#343434" />}
              onClick={handleSubmit(openOTP)}
            >
              {t("Continue")}
            </ButtonJack>
          </div>
        </Layout>
      </Modal>
      <OTPSequenceModal
        isOpen={isOpenOTP && isOpen}
        toggle={closeOTP}
        mobile={currentMobileNumber}
        isLoading={loadingReqOTP}
        handleRequestOTP={(otp_with) => {
          const { email, password, is_encrypted } = additionalPayload;
          const mobile = watch("mobile");
          const country_code = phoneCode.find(
            ({ code }) => watch("country_code")?.value == code
          )?.value;

          requestOTP({
            email,
            password,
            is_encrypted,
            otp_with,
            mobile,
            country_code,
          });
        }}
        onClickSubmitOTP={(otp_code) => onSubmit(otp_code, closeOTP)}
      />
    </>
  );
};
